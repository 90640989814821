$bg-color: #f5f0ec;

.error-container {
  height: 100vh;
  background-color: $bg-color;
  display: flex;

  .error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: clamp(85px, 12vw, 12vw);
    font-weight: 700;
    margin-bottom: 5vh;
    color: #000;
    text-transform: uppercase;
    font-family: Anton, sans-serif;
    text-align: center;
    line-height: 1.1;
  }

  button {
    background: #111;
    border: 1px solid #111;
    padding: 20px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 3px;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
