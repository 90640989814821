@import "../../../mixins";

.container {
  position: relative;

  .title {
    font-size: 18vw;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    line-height: 100%; //fixa?
    margin-top: 20px;

    @include mq("tablet", max) {
      font-size: 25vw;
    }
    @include mq("phone-wide", max) {
      font-size: 20vw;
    }
  }
}

.lineParent {
  overflow: hidden;
}

.lineChildren {
  transform: translate(0, 500px);
}