@import "../../../mixins";

.container {
  position: relative;
  .title-mt {
    font-size: 15vw;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    line-height: 100%; //fixa?
    margin-top: 20px;
  }
}

.lineParent {
  overflow: hidden;
}

.lineChildren {
  transform: translate(0, 500px);
}
