@import "../../../mixins";

.container {
  position: relative;
  .title-yay {
    font-size: 15vw;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    line-height: 100%; //fixa?
    margin-top: 20px;
    @include mq("phone-wide", max) {
      font-size: 16vw;
    }
  }
}

.lineParent {
  overflow: hidden;
}

.lineChildren {
  transform: translate(0, 500px);
}