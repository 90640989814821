@import "../../../mixins";

.featured {
  .featured-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .sv-img {
      height: 40vh;
      width: auto;

      @include mq("tablet-wide", max) {
        height: 15vh;
        width: auto;
      }
    }
  }
}
