@import "../mixins";

.about-main-container {
  background-color: #d4c9c9;

  section {
    padding: 0;
  }

  // HERO
  .hero-container {
    position: relative;
    height: 100vh;
    img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }

    .hero-title {
      position: absolute;
      bottom: 1vw;
      left: 5vw;
      font-size: clamp(72px, 15vw, 15vw);
      color: white;
      font-family: "Oswald", sans-serif;
      text-transform: lowercase;
      font-weight: 100;
    }
  }
  // FLYING SKILLS
  .skills-container {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f2;

    @include mq("tablet-wide", max) {
      display: none;
    }

    .skills-title {
      color: #394437;
      text-align: center;
      font-family: "Space Grotesk", sans-serif;
      font-size: 8.5vw;
      text-transform: lowercase;
      z-index: 1;
      line-height: 1.1;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0.55);
      color: transparent;
    }
  }

  .mobile {
    min-height: 50vh;
    padding: 30px;
    background-color: #cbcbce;
    color: #394437;
    text-align: center;
    font-family: "Space Grotesk", sans-serif;
    font-size: 8.5vw;
    text-transform: lowercase;
    z-index: 1;
    line-height: 1.6;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.55);
    color: transparent;

    @include mq("tablet-wide", min) {
      display: none;
    }
    ul {
      padding: 0;
      margin: 0;
    }
  }

  // DESCRIPTION
  .intro-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e2;

    p {
      max-width: 800px;
      color: #394437;
      font-size: 26px;
      line-height: 2;
      font-weight: 300;
      text-align: left;
      margin: 30px;

      @include mq("tablet", max) {
        font-size: 16px;
        text-align: justify;
      }
    }
  }
  // RESUME
  .bottom-container {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #cbcbce;

    @include mq("phone-wide", max) {
      flex-direction: column;
    }
    .bottom-title {
      font-family: "Space Grotesk", sans-serif;
      font-size: 8.5vw;
      text-transform: lowercase;
      line-height: 1.1;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0.55);
      color: transparent;
      margin: 50px;

      a {
        text-decoration: underline;
        color: #e8e8e9;
        &:hover {
          color: black;
        }
      }
    }

    .bottom-image {
      width: 500px;
      height: auto;
      margin: 0 50px;

      &:hover {
        -webkit-filter: grayscale(70%);
      }

      @include mq("tablet-wide", max) {
        width: 300px;
      }
    }
  }
}
