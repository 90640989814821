.footer-container-sogeti {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding-bottom: 100px;

  a {
    color: #394437;
    font-family: "Space Grotesk", sans-serif;
    font-size: 6.5vw;
    text-transform: lowercase;
    z-index: 1;
    line-height: 1.1;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.55);
    color: transparent;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: rgba(0, 0, 0, 0.35);
  }
}
