@import "../../mixins";

.project-item-container {
  cursor: pointer;
  line-height: 1.25;
  padding: 5px 0;
  scroll-behavior: unset;

  .title-item {
    position: relative;
    display: flex;

  }

  img {
    position: fixed;
    left: 35vw;
    width: auto;
    height: clamp(200px, 20vw, 20vw);
    object-fit: cover;
    top: 30vh;
    pointer-events: none;

    //This is to add timing function for  mouse image parallax
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);

    @include mq("tablet", max) {
      display:none;  
      }
  }

  .info-block {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 80vw;
    z-index: 1;
    text-align: left;
    font-size: 20px;

    .info-block-header {
      margin-bottom: 20px;
      color: black;
      font-weight: 500;
      font-size: 24px;

      svg {
        margin-right: 4px;
      }
      span {
        display: flex;
        align-items: center;
      }
    }

    p {
      margin-bottom: 10px;
      overflow: hidden;
      color: #999;
      span {
        display: block;
        opacity: 0;
        transition: all 0.25s ease-in-out;
        transform: translateY(10px);
      }
    }

    &.as-active {
      @for $i from 0 through 4 {
        p:nth-child(#{$i + 1}n) {
          span {
            transition-delay: #{$i/10}s;
          }
        }
      }
      span {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
  @include mq("tablet", max) {
    padding: 25px 0;
    .info-block {
      font-size: 10px;
    }
  }
}

.menu-title {
  font-family: "Space Grotesk", sans-serif;
  font-size: 9vw;
  text-transform: lowercase;
  z-index: 1;
  line-height: 1.1;

    
  &.clone {
    position: absolute;
    top: 0;
    //top: -98px; // why not 0?
    top: 0;
    pointer-events: none;
    color: black;
    clip-path: inset(0 100% 0 0);
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &:not(.clone) {
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.25);
    color: transparent;

    @include mq("tablet-wide", max) {
      color: #394437;
    }

    &:hover {
      cursor: pointer;
      @include mq("desktop", min) {
        ~ .clone {
          clip-path: inset(0 0 0 0);
      }
    }
      
      ~ .menu-image-container .menu-image {
        opacity: 1;
        transform: scale(1.1);
        filter: blur(0) brightness(1);
      }
    }
  }
}
