@import "../../../mixins";

.featured-section {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;

  img {
    clip-path: inset(100% 0% 0% 0%);
    transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
    &.is-reveal {
      clip-path: inset(0% 0% 0% 0%);
    }
  }

  h6 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .featured-row-layout,
  .featured-column-layout {
    display: grid;
    gap: 10px;
  }

  .featured-row-layout {
    grid-template-rows: repeat(2, auto);

    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .featured-column-layout {
    grid-template-columns: 100px auto;
    align-items: flex-end;

    @include mq("phone-wide", max) {
      grid-template-columns: 5px auto;
    }

    .sogeti-img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
