@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");

@import "./mixins";

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

:root {
  --main-color: rgb(91, 91, 241);
}

::-webkit-scrollbar {
  display: none;
}

ul,
li {
  list-style-type: none;
}

a {
  color: #fff;
  text-decoration: none;
}

.main-container {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  position: relative;
  width: calc(100vw - 50px);
  margin: 0 auto;

  h1 {
    font-size: clamp(85px, 12vw, 12vw);
    font-weight: 700;
    margin-bottom: 5vh;
    max-width: 70vw;
    text-transform: uppercase;
    font-family: Anton, sans-serif;
    color: #111;
    line-height: 100%; //kolla denna?
    letter-spacing: 5px;
    @include mq("tablet-wide", min) {
      font-size: 12vh;
    }
  }

  h3 {
    text-transform: uppercase;
  }

  .projectPage-list {
    scroll-behavior: smooth;
    position: relative;
    width: 100vw;
    height: 100vh;
    padding-left: 10vw;
    box-sizing: border-box;
    overflow: auto;
  }

  @include mq("tablet", min) {
    margin-left: 75px;
    padding-left: 50px;
    border-left: 1px solid rgb(116, 116, 116);
    width: calc(100vw - 50px);
  }
}
