@import "../mixins";

.about-main-container {
  background-color: #d4c9c9;

  // HERO
  .hero-container {
    position: relative;
    height: 100vh;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
    button {
      position: absolute;
      top: 40vw;
      right: 15vw;
      border: 1px solid #111;
      background: #111;
      padding: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 3px;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      font-family: "Oswald", sans-serif;
      font-weight: 100;
      &:hover {
        border: 1px solid #fff; // change hoverstate?
      }
    }
    .hero-title {
      position: absolute;
      bottom: 1vw;
      left: 5vw;
      //font-size: 30px;
      font-size: clamp(72px, 15vw, 15vw);
      color: white;
      font-family: "Oswald", sans-serif;
      text-transform: lowercase;
      font-weight: 100;
    }
  }

  // INFO
  .info-container {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #bdbdc0;

    @include mq("phone-wide", max) {
      flex-direction: column;
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      color: #fff;
      span {
        font-weight: bold;
      }
      .info-title {
        font-family: "Space Grotesk", sans-serif;
        font-size: 5.5vw;
      }
    }

    .info-image {
      width: auto;
      height: 80vh;
      margin: 0 20px;

      @include mq("tablet-wide", max) {
        width: auto;
        height: 40vh;
      }
    }
  }

  // CONTACT
  .base-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #cbcbce;

    .base-title {
      display: inline-block;
      vertical-align: middle;
      margin-left: 1.25rem;
      line-height: 1.1;
    }
    .base-title span {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-family: "Space Grotesk", sans-serif;
      font-size: 15.5vw;
      -webkit-text-stroke: 1px rgba(0, 0, 0, 0.55);
      color: transparent;
    }
    .external-nav-links {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 0;
      li {
        margin: 20px;
        a {
          color: rgba(0, 0, 0, 0.45);
          &:hover {
            color: #111;
          }
          svg {
            width: 5vw;
            height: auto;
            stroke-width: 0.4px;
          }
        }
      }
    }
  }
}
