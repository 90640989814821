@import "../../../mixins";

.about-section {
  #headline {
    font-size: 60px;
    line-height: 1.12;
    opacity: 0;

    @include mq("tablet-wide", max) {
      display: none;
    }
    > div {
      opacity: 0;
      transform: translateY(0px);
    }
    &.is-reveal {
      opacity: 1;
    }
  }
  .mobile {
    font-weight: 100;
    letter-spacing: 1px;
    color: #626262;
    @include mq("tablet-wide", min) {
      display: none;
    }
  }
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .img-sv {
    height: 40vh;
    width: auto;
    @include mq("tablet-wide", max) {
      height: 20vh;
      width: auto;
    }
  }
}
