@import "../../../mixins";

.featured {
  .featured-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .sg-img {
      height: 30vh;
      width: auto;
      @include mq("tablet-wide", max) {
        height: auto;
        width: 80vw;
      }
    }
  }
}

