.skills-main-container {
  background-color: #cbcbce;
  height: 100vh;

  .base-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #cbcbce;

    .skills-header {
      text-align: left;
      color: #394437;
      font-family: "Space Grotesk", sans-serif;
      font-size: 8vw;
      text-transform: lowercase;
    }

    .gallery {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-width: 33%;
      -moz-column-width: 33%;
      column-width: 33%;
      padding: 0 12px;

      .gallery .pics {
        -webkit-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;
      }

      .gallery .pics:hover {
        filter: opacity(0.8);
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .model {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-self: center;
      background-color: #000000de;
      transition: opacity 0.4s ease, visibility 0.4s ease,
        transform 0.5s ease-in-out;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      overflow: hidden;
      z-index: 9;
    }

    .model.open {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }

    .model img {
      width: fit-content;
      height: fit-content;
      display: block;
      line-height: 0;
      box-sizing: border-box;
      padding: 20px 0 20px;
      margin: 0 auto;
    }

    .model.open svg {
      position: fixed;
      top: 10px;
      right: 10px;
      width: 3rem;
      height: 3rem;
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.4);
      color: #fff;
      cursor: pointer;
    }

    @media (max-width: 991px) {
      .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
        column-width: 50%;
      }
    }
  }
}