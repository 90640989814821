$bg-color: #f5f0ec;
@import "../../mixins";

.project-container {
  min-height: 100vh;
  padding: 0 5vw;
  background-color: $bg-color;

  section {
    padding-top: 30px;
  }
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #191919;
  color: #dbd8d6;

  h1 {
    font-size: 8vw;
    text-transform: uppercase;
    font-weight: 600;
  }
  h2 {
    font-size: 6.5vw;
    text-transform: uppercase;
    font-family: Anton, sans-serif;
    font-weight: italic;
    margin-top: 10px;
    letter-spacing: 5px;
  }
}

.absolute {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: 1;
}
